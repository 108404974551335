export default {
  computed: {
    nameStatus() {
      const error = this.$v.form.name.$error;

      return {
        error: error ? 'is-danger' : '',
        message: error ? 'Name field is required' : '',
      };
    },
  },
};
