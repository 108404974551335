<template>
  <div
    ref="modal"
    :class="[$style.modal, { [$style['modal--headless']]: !title }]"
    tabindex="0"
    @keydown.enter="submit"
    @keydown.esc="$emit('close')"
  >
    <div
      v-if="title"
      :class="[$style.header, handlerStyle]"
    >
      <div :class="$style.title">
        {{ title }}
      </div>
      <button
        v-if="showCloseBtn"
        type="button"
        :class="['button', 'is-ghost', 'p-0', $style['x-button']]"
        style="min-width: auto"
        @click="$emit('close')"
      >
        <font-awesome-icon
          :icon="['fal', 'xmark']"
        />
      </button>
    </div>

    <div
      v-if="message"
      :class="[$style.body, { [handlerStyle]: !title }]"
    >
      <slot>{{ message }}</slot>
    </div>

    <div :class="$style.footer">
      <b-button
        :type="submitBtnType"
        :class="$style.footer__btn"
        @click="submit"
      >
        {{ submitBtnText }}
      </b-button>

      <b-button
        type="is-light"
        :class="$style.footer__btn"
        @click="$emit('close')"
      >
        {{ closeBtnText }}
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Confirm',
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: [String, Boolean],
      default: '',
    },
    onSubmit: {
      type: Function,
      default: null,
    },
    submitBtnType: {
      type: String,
      default: 'is-primary',
    },
    submitBtnText: {
      type: String,
      default: 'OK',
    },
    closeBtnText: {
      type: String,
      default: 'Close',
    },
    showCloseBtn: {
      type: Boolean,
      default: false,
    },
    handlerStyle: {
      type: String,
      default: 'modal-handler',
    },
    closeAfterSubmit: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.$nextTick(() => this.$refs.modal?.focus());
  },
  methods: {
    submit() {
      if (this.onSubmit) this.onSubmit();
      this.$emit('submit');
      if (this.closeAfterSubmit) this.$emit('close');
    },
  },
};
</script>
<style lang="scss" module>
  .modal {
    display: flex;
    width: 100%;
    flex-direction: column;
    max-height: calc(100vh - 40px);
    overflow: hidden;
    word-break: break-word;

    &:focus-visible {
      outline: none;
    }

    &--headless {
      .header {
        display: none;
      }
    }
  }
  .header {
    display: flex;
    padding: 10px 20px;
    border-bottom: none;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    background-color: var(--color-white);

    .x-button {
      height: auto;
      margin-left: 10px;
      color: inherit;
    }
  }

  .title {
    color: hsl(0deg, 0%, 21%);
    flex-grow: 1;
    margin-right: auto;
    font-weight: 600;
    font-size: 17px;
    line-height: 27px;
  }

  .body {
    padding: 10px 20px 20px;
    background-color: var(--color-white);
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 15px;
      height: 15px;;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border: 5px solid #fff;
      border-radius: 10px;
      background-color: #C0C0C0;
    }
  }

  .footer {
    padding: 9px 8px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    background-color: var(--color-white);
    border-top: 1px solid hsl(0deg, 0%, 86%);

    .footer__btn {
      font-weight: 400;
      min-width: 75px;
      &:not(:last-child) {
        margin-right: 0.5em;
      }
    }
  }
</style>
