<template>
  <div :class="$style.title">
    {{ value }}
  </div>
</template>

<script>
export default {
  name: 'SettingsTitle',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" module>
.title {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: var(--color-main);
  }
</style>
