var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.teams},[_c('settings-title',{attrs:{"value":'My team'}}),(_vm.ownTeam)?[_c('div',{class:_vm.$style.name},[_c('div',{class:_vm.$style.label},[_vm._v(" Name: ")]),(_vm.ownTeam.name)?_c('div',{class:_vm.$style.text},[_vm._v(" "+_vm._s(_vm.ownTeam.name)+" ")]):_vm._e(),_c('div',{class:_vm.$style.icon,on:{"click":_vm.changeTeamName}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'pencil']}})],1)]),_c('div',{class:_vm.$style.invite},[_c('b-button',{attrs:{"type":"is-primary","icon-left":"plus"},on:{"click":_vm.inviteUser}},[_vm._v(" Invite user ")])],1),_c('b-table',{class:_vm.$style.table,attrs:{"data":_vm.participants,"row-class":_vm.rowClass}},[_c('b-table-column',{attrs:{"width":"40%","label":"User"},scopedSlots:_vm._u([{key:"default",fn:function({ row: { userName, confirmed, email } }){return [_vm._v(" "+_vm._s(userName)+" "),(!confirmed)?[_vm._v(" ("),_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.sendInvitation({ email })}}},[_vm._v(" Send again ")]),_vm._v(") ")]:_vm._e()]}}],null,false,47806695)}),_c('b-table-column',{attrs:{"label":"Email"},scopedSlots:_vm._u([{key:"default",fn:function({ row: { email } }){return [_vm._v(" "+_vm._s(email)+" ")]}}],null,false,3196234119)}),_c('b-table-column',{attrs:{"width":"16px"},scopedSlots:_vm._u([{key:"default",fn:function({
          row: {
            owner, id, userName, confirmed, email,
          },
        }){return [(!owner)?_c('div',{class:_vm.$style.icon,attrs:{"title":"Delete"},on:{"click":function($event){return _vm.deleteUser({
            id, userName, confirmed, email,
          })}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'trash-can']}})],1):_vm._e()]}}],null,false,3006204034)})],1)]:_vm._e(),_c('settings-title',{attrs:{"value":'Other teams you belong to'}}),(_vm.otherTeams.length)?_c('b-table',{class:_vm.$style.table,attrs:{"data":_vm.otherTeams}},[_c('b-table-column',{attrs:{"label":"Team"},scopedSlots:_vm._u([{key:"default",fn:function({ row: { name } }){return [_vm._v(" "+_vm._s(name)+" ")]}}],null,false,3379132967)}),_c('b-table-column',{attrs:{"width":"16px"},scopedSlots:_vm._u([{key:"default",fn:function({ row: { ownerId, name } }){return [_c('div',{class:_vm.$style.icon,attrs:{"title":"Leave the team"},on:{"click":function($event){return _vm.leave({ ownerId, name })}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'link-slash']}})],1)]}}],null,false,24589692)})],1):_c('div',[_vm._v(" Here you will see the list of teams where you accepted invitation. ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }