<template>
  <div
    ref="modal"
    :class="[$style.modal, { [$style['modal--headless']]: !title }]"
    tabindex="0"
    @keydown.enter="$emit('close')"
  >
    <div :class="[$style.header, handlerStyle]">
      <div :class="$style.title">
        {{ title }}
      </div>
    </div>

    <div :class="$style.body">
      <slot>{{ message }}</slot>
    </div>

    <div :class="$style.footer">
      <b-button
        type="is-primary"
        @click="$emit('close')"
      >
        {{ btnText }}
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    btnText: {
      type: String,
      default: 'OK',
    },

    handlerStyle: {
      type: String,
      default: 'modal-handler',
    },
  },
  mounted() {
    this.$nextTick(() => this.$refs.modal?.focus());
  },
};
</script>
<style lang="scss" module>
  .modal {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 40px);
    overflow: hidden;
    word-break: break-word;

    &:focus-visible {
      outline: none;
    }

    &--headless {
      .header {
        display: none;
      }
    }
  }

  .header {
    display: flex;
    padding: 10px 20px;
    border-bottom: none;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    background-color: var(--color-white);
  }

  .title {
    color: hsl(0deg, 0%, 21%);
    flex-grow: 1;
    flex-shrink: 0;
    margin-right: auto;
    font-weight: 600;
    font-size: 17px;
    line-height: 27px;
    word-break: break-all;
  }

  .body {
    padding: 10px 20px 20px;
    background-color: var(--color-white);
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
  }

  .footer {
    padding: 9px 8px 10px;
    display: flex;
    justify-content: flex-end;
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    background-color: var(--color-white);
    border-top: 1px solid hsl(0deg, 0%, 86%);
  }
</style>
