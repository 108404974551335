export default {
  computed: {
    emailStatus() {
      const error = this.$v.form.email.$error;
      const requiredError = !this.$v.form.email.required;
      const emailError = !this.$v.form.email.email;
      const requiredMessage = (error && requiredError) ? 'Email field is required' : '';
      const emailMessage = (error && emailError) ? 'Wrong Email format' : '';

      return {
        error: error ? 'is-danger' : '',
        message: requiredMessage || emailMessage,
      };
    },
  },
};
