<template>
  <div class="modal-card">
    <div class="modal-card-head">
      <p class="modal-card-title">
        Invite by email
      </p>
    </div>

    <form @submit.prevent="submit">
      <div class="modal-card-body">
        <b-field
          :type="emailStatus.error"
          :message="emailStatus.message"
        >
          <b-input
            ref="firstField"
            v-model="form.email"
            placeholder="email@domain.com"
            :disabled="loading"
          />
        </b-field>

        <b-field
          v-if="serverError"
          class="info"
        >
          <div class="error">
            {{ serverError }}
          </div>
        </b-field>
      </div>

      <div class="modal-card-foot">
        <b-button
          :loading="loading"
          type="is-primary"
          native-type="submit"
        >
          Send invitation
        </b-button>

        <b-button
          :disabled="loading"
          type="is-light"
          @click="$emit('close')"
        >
          Close
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import autoFocus from '@/views/settings/mixins/autoFocus.js';
import emailMixin from '@/views/settings/mixins/emailMixin.js';
import { required, email } from '@/utils/validators.js';
import { useTeamsStore } from '@/stores/index.js';

export default {
  name: 'ChangeEmail',
  mixins: [autoFocus, emailMixin],
  props: {
    email: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      serverError: null,
      loading: false,
      form: {
        email: this.email,
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    ...mapActions(useTeamsStore, ['sendInvitation', 'readOwnTeam']),

    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) return false;

      this.serverError = null;
      this.loading = true;

      const { error } = await this.sendInvitation(this.form);

      this.loading = false;

      if (error) {
        this.serverError = error;
        return this.serverError;
      }

      this.$emit('close');

      return this.readOwnTeam();
    },
  },
};
</script>
