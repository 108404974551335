<template>
  <div :class="$style.teams">
    <settings-title :value="'My team'" />

    <template v-if="ownTeam">
      <div :class="$style.name">
        <div :class="$style.label">
          Name:
        </div>

        <div
          v-if="ownTeam.name"
          :class="$style.text"
        >
          {{ ownTeam.name }}
        </div>

        <div
          :class="$style.icon"
          @click="changeTeamName"
        >
          <font-awesome-icon :icon="['fal', 'pencil']" />
        </div>
      </div>

      <div :class="$style.invite">
        <b-button
          type="is-primary"
          icon-left="plus"
          @click="inviteUser"
        >
          Invite user
        </b-button>
      </div>

      <b-table
        :data="participants"
        :row-class="rowClass"
        :class="$style.table"
      >
        <b-table-column
          v-slot="{ row: { userName, confirmed, email } }"
          width="40%"
          label="User"
        >
          {{ userName }}
          <template v-if="!confirmed">
            (<a @click.prevent="sendInvitation({ email })">
              Send again
            </a>)
          </template>
        </b-table-column>

        <b-table-column
          v-slot="{ row: { email } }"
          label="Email"
        >
          {{ email }}
        </b-table-column>

        <b-table-column
          v-slot="{
            row: {
              owner, id, userName, confirmed, email,
            },
          }"
          width="16px"
        >
          <div
            v-if="!owner"
            :class="$style.icon"
            title="Delete"
            @click="deleteUser({
              id, userName, confirmed, email,
            })"
          >
            <font-awesome-icon :icon="['fal', 'trash-can']" />
          </div>
        </b-table-column>
      </b-table>
    </template>

    <settings-title :value="'Other teams you belong to'" />

    <b-table
      v-if="otherTeams.length"
      :data="otherTeams"
      :class="$style.table"
    >
      <b-table-column
        v-slot="{ row: { name } }"
        label="Team"
      >
        {{ name }}
      </b-table-column>

      <b-table-column
        v-slot="{ row: { ownerId, name } }"
        width="16px"
      >
        <div
          :class="$style.icon"
          title="Leave the team"
          @click="leave({ ownerId, name })"
        >
          <font-awesome-icon :icon="['fal', 'link-slash']" />
        </div>
      </b-table-column>
    </b-table>

    <div v-else>
      Here you will see the list of teams where you accepted invitation.
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import InviteUserModal from '@/views/settings/modals/invite-user-modal.vue';
import ChangeTeamNameModal from '@/views/settings/modals/change-team-name-modal.vue';
import Alert from '@/components/modals/alert.vue';
import Confirm from '@/components/modals/confirm.vue';
import SettingsTitle from '@/views/settings/components/title.vue';
import { useSettingsStore, useTeamsStore } from '@/stores/index.js';

export default {
  name: 'TeamsSettings',
  components: { SettingsTitle },

  data() {
    return {
      optionsModal: {
        clickToClose: false,
        classes: 'modal',
        width: '440px',
        height: 'auto',
        draggable: '.modal-handler',
        adaptive: true,
      },
    };
  },

  computed: {
    ...mapState(useTeamsStore, ['availableTeams', 'ownTeam']),
    ...mapState(useSettingsStore, ['getProfile']),
    participants() {
      return [
        {
          userName: `${this.getProfile.name} (Owner)`,
          email: this.getProfile.email,
          confirmed: true,
          owner: true,
        },
        ...this.ownTeam?.participants,
      ];
    },
    otherTeams() {
      return this.availableTeams?.filter(({ ownerId }) => ownerId !== this.getProfile?.sub)
        || [];
    },
  },

  created() {
    this.readOwnTeam();
    this.readTeams();
  },

  beforeDestroy() {
    this.modal?.$children[0]?.$emit('close');
  },

  methods: {
    ...mapActions(useTeamsStore, [
      'readOwnTeam',
      'readTeams',
      'sendInvitation',
      'deleteTeamUser',
      'deleteInvitations',
      'leaveTeam',
    ]),

    rowClass({ confirmed }) {
      return !confirmed && this.$style.notConfirmed;
    },

    async changeTeamName() {
      this.modal = this.$modal.show(
        ChangeTeamNameModal,
        { name: this.ownTeam.name },
        { ...this.optionsModal, draggable: '.modal-card-head' },
      );
    },

    async inviteUser() {
      this.modal = this.$modal.show(InviteUserModal, {}, { ...this.optionsModal, draggable: '.modal-card-head' });
    },

    async deleteUser({
      id,
      userName,
      confirmed,
      email,
    }) {
      if (id === this.getProfile.sub) {
        return (this.modal = this.$modal.show(
          Alert,
          { title: 'You can\'t delete the owner of the team.' },
          this.optionsModal,
        ));
      }

      return (this.modal = this.$modal.show(
        Confirm,
        {
          title: `Delete the user ${userName}?`,
          onSubmit: async () => {
            if (confirmed) {
              await this.deleteTeamUser({ userId: id });

              return this.readOwnTeam();
            }

            await this.deleteInvitations({ email });

            return this.readOwnTeam();
          },
        },
        this.optionsModal,
      ));
    },

    async leave({ ownerId, name }) {
      return (this.modal = this.$modal.show(
        Confirm,
        {
          title: `Are you sure you want to leave the ${name} team?`,
          onSubmit: async () => {
            await this.leaveTeam({ ownerId });

            return this.readTeams();
          },
        },
        this.optionsModal,
      ));
    },
  },
};
</script>

<style lang="scss" module>
.teams {
  .title {
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
    color: var(--color-main);
  }

  .name {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;

    .label {
      font-weight: 700;
      color: var(--color-black);
    }

    .text {
      word-break: break-all;
    }
  }

  .icon {
    display: inline-flex;
    color: var(--color-main);
    transition-duration: var(--transition-duration);
    cursor: pointer;

    &:hover {
      transform: scale(1.4);
    }
  }

  .invite {
    margin-bottom: 10px;
  }

  .table {
    margin-bottom: 40px;
    max-width: 800px;

    .notConfirmed {
      color: #b4b4b4;
    }
  }
}
</style>
